.toolbar-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    background-color: black;

}

.login-button {
    padding: 0.5rem;
    margin: 1rem;
    background-color: white;
    border-radius: 5px;
}

.login-button a {
    color: black;
    text-decoration: none;
    font-family: 'IBM Plex Mono', monospace;
}
