body {
  padding: 0;
  margin: 0;
  font-family: 'IBM Plex Mono', monospace;
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

footer {
  display: flex;
  height: 100px;
  background-color: #000;
  align-items: center;
  justify-content: center;
}
footer a {
  color: #fff;
}

.grey {
  background-color: #a9a9a9;
}
.white {
  background-color: #fff;
}
.black {
  background-color: #000;
}
